<template>
  <div align="center">
    <v-toolbar dense class="rounded">
      <v-btn icon class="hidden-xs-only" @click="$router.push('/')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title
        >TÉRMINOS Y CONDICIONES GENERALES</v-toolbar-title
      >

      <v-spacer></v-spacer>
    </v-toolbar>
    <iframe
      :src="gameUrl"
      ref="myIframe"
      :style="
        size < 960
          ? 'width: 100%; height: calc('+this.screenHeight*0.91+'px)'
          : 'width: 100%; height: calc(100vh - 95px)'
      "
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      seccion: "",
      gameUrl:""
    };
  },
  watch: {
    seccion() {
      this.getSeccion();
      this.seccion = this.$route.params.seccion;
      
      //this.showChat();
    },
  },
  mounted() {
    this.getSeccion();
  },
  updated() {
    if (document.getElementById("fc_frame")) {
      document.getElementById("fc_frame").style.display = "none";
    }
  },
  methods: {
    getSeccion() {
      this.seccion = this.$route.params.seccion;
      this.gameUrl="https://apitr.xyz/dominio/tr/tycg/#"+this.seccion
      
    },
  },
};
</script>

<style>
.p {
  background-color: #fff;
}
.tabl {
  border: 1px solid #c0c0c0;
  border-collapse: collapse;
  padding: 5px;
  background: white;
  color: black;
  border-radius: 4px !important;
  width: 100%;
}
.tabd {
  border: 1px solid #c0c0c0;
  border-collapse: collapse;
  padding: 10px;
  background: white;
  color: black;
  border-radius: 4px !important;
  width: 100%;
}
.tabl th {
  border: 1px solid #c0c0c0;
  padding: 5px;
  border-radius: 4px !important;
  font-size: 12px !important;
}
.tabl td {
  border: 1px solid #c0c0c0;
  padding: 5px;
  border-radius: 4px !important;
}
</style>
